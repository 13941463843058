function isUserLoggedIn(): boolean {
    try {
        const status = localStorage.getItem("authToken");
        if (status) {
            return true;
        }
    } catch (error) {
        return false;
    }

    return false;
}

export default isUserLoggedIn;
