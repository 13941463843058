/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import app from "@/main";

interface ErrorReturnInterface {
    status: number;
    errors: { message: any }[];
}

export default function resolveRequestError(e: any): ErrorReturnInterface {
    let errors = [{ message: "Connection seems to be very slow" }];
    const status = e.response ? e.response.status : 0;
    const { data } = e.response;

    const checkError = (dataToCheck: any) => {
        let errorToReturn = [];

        if (typeof dataToCheck === "string") {
            errorToReturn = [{ message: dataToCheck }];
        } else if (Array.isArray(dataToCheck)) {
            errorToReturn = dataToCheck;
        } else if (Array.isArray(dataToCheck.errors)) {
            errorToReturn = dataToCheck.errors;
        } else {
            errorToReturn = [
                {
                    message:
                        dataToCheck.description ||
                        dataToCheck.message ||
                        dataToCheck.status,
                },
            ];
        }

        return errorToReturn;
    };

    if (status === 400 || status === 404 || status === 422 || status === 403) {
        errors = checkError(data);
    } else if (status === 401) {
        errors = [{ message: "You are not logged in" }];
        localStorage.clear();
        if (document.location.pathname === "/login") {
            errors.push(checkError(data));
        } else {
            app.$router.replace({
                path: `/login?next=${document.location.pathname}`,
            });
        }
    } else if (status === 404) {
        errors = [{ message: "Oops! an error occured" }];
    } else if (status === 500) {
        errors = [{ message: "Oops! A Server error occured" }];
    }

    // errors.forEach((error) => {
    //     const { message } = error;

        app.$bvToast.toast(errors[0].message, {
            title: "Something went wrong",
            variant: "danger",
            solid: true,
        });
    // });

    return {
        status,
        errors,
    };
}
