/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import ReportService from "@/api/report-service";

const state = {
    reports: [],
    reportDetails: {},
    existingReports: "",
    boardCommitteeInfo: {},
    isReportDetailsSaved: false
};
const mutations = {
    storeReports(currentState: any, data: any): void {
        currentState.reports = data;
    },
    removeReport(currentState: any, reportId: string): void {
        const reportIndex = currentState.reports?.findIndex(
            (rep: any) => rep.id === reportId,
        );

        currentState.reports.splice(reportIndex, 1);
    },
    SET_EXISTING_REPORT(currentState: any, data: any): void {
        currentState.existingReports = data
    },
    SET_REPORT_BY_ID(currentState: any, data: any): void {
        currentState.reportDetails = data;
    },
    SET_BOARD_COMMITTEE_DATA(currentState: any, data: any): void {
        currentState.boardCommitteeInfo = data;
    },
    SET_DETAILS_STATE(currentState: any, data: any): void {
        currentState.isReportDetailsSaved = data;
    },
};
const actions = {
    async fetchReportList({ commit }: any): Promise<void> {
        const { data } = await ReportService.fetchReportList();
        // console.log(data);
        commit("storeReports", data);
    },
    // async fetchReportDetails({ commit }: any, payload: string): Promise<void> {
    //     const { data } = await ReportService.fetchReportDetails(payload);
    //     console.log("from the store", data);
    // },
    /**
     * Make Creator Action
     * @param _
     * @param payload
     */
    async makeCreator(_: any, payload: Array<string>): Promise<void> {
        const { data } = await ReportService.makeCreator(payload);
        // commit("storeUsers", data);
        return data;
    },
    /**
     * Make Reviewer Action
     * @param _
     * @param payload
     */
    async makeReviewer(_: any, payload: Array<string>): Promise<void> {
        const { data } = await ReportService.makeReviewer(payload);
        // commit("storeUsers", data);
        return data;
    },
    /**
     * Make Signatory Action
     * @param _
     * @param payload
     */
    async makeSignatory(_: any, payload: Array<string>): Promise<void> {
        const { data } = await ReportService.makeSignatory(payload);
        // commit("storeUsers", data);
        return data;
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
