/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */

import FilesService from "@/api/files";
import resolveRequestError from "@/utils/errorHandler";

const state = {
    file: {},
    files: {},
};

const mutations = {
    storeFileDetails(currentState: any, data: any): void {
        currentState.files = {
            ...currentState.files,
            [data.fileName]: data.file,
        };
    }, 
};
const actions = {
    /**
     * Fetch File Details Action
     * @param commit
     * @param payload
     */
    async fetchFile({ commit }: any, payload: string): Promise<void> {
        try {
            const { data } = await FilesService.fetchFile(payload);
            commit("storeFileDetails", { fileName: payload, file: data });
            return data;
        } catch (error) {
            throw resolveRequestError(error);
        }
    },

    /**
     * Upload File Action
     * @param commit
     * @param payload
     */
    async uploadFile({ commit }: any, payload: any): Promise<void> {
        try {
            const { data } = await FilesService.uploadFile(payload);
            commit("storeFileDetails", data);
            return data;
        } catch (error) {
            throw resolveRequestError(error);
        }
    },

    /**
     * Upload File Action
     * @param _
     * @param payload
     */
    async removeFile(_: any, payload: string): Promise<void> {
        try {
            const { data } = await FilesService.removeFile(payload);
            return data;
        } catch (error) {
            throw resolveRequestError(error);
        }
    },
};
const getters = {
    getFile: ({ file }: any): any => file,
    getFiles: ({ files }: any): any => files,
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
