import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth.module";
import reports from "./modules/reports.module";
import users from "./modules/users.module";
import company from "./modules/company.module";
import file from "./modules/file.module";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        reports,
        users,
        company,
        file,
    },
});
